// Theme specific styling.

@import 'settings';
@import 'typography';
@import 'breakpointdebug';
@import 'node_modules/baguettebox.js/src/baguetteBox';
@import 'node_modules/prismjs/themes/prism';

.visually-hidden:not(:focus):not(:active) {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}
.skip-link {
    font-family: $font-serif;
    font-size: $size-large;
    font-weight: bold;
    position: absolute;
    top: 60px;
    z-index: 1;
}

.navbar-brand {
    .navbar-item {
        font-family: $font-serif;
        font-size: $size-large;
        font-weight: bold;
    }
}

.navbar-end {
    align-items: center;
    @include mobile {
        form {
            padding: 1rem;
            .control:first-child {
                width: 100%;
            }
        }
    }
}

.navbar.is-primary, .boltform button.navbar {
    background-color: #D4D6C1;
}

.navbar.is-primary .navbar-start > .navbar-item {
    color: #113e65c2;
    padding: 0.9rem 0.75rem 0.2rem;
}

.navbar-item-logo {
    padding: 0.5rem 0.75rem;
    img {
        max-height: 50px;
    }
}

.notification {
    ul {
        margin: 0.5em 1.4em;
        list-style-type: disc;
    }
}

.teaser {
    font-weight: bold;
    margin-bottom: 1rem;
}

.card {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.card-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .button:last-child {
        margin-top: auto;
    }

    p {
        height: 100%;
    }
}

.section-latest-entries {
    .buttons {
        margin-top: 1.5rem;
    }
}

.tags {
    margin-top: 3rem;
    .label {
        margin-right: .5em;
    }
}

.section-record {
    .image {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

.button {
    text-decoration: none;
}

p.meta {
    margin-top: 1rem;
    color: $medium;
    font-size: 90%;
}

.media {
    @include mobile {
        flex-direction: column;
    }
}
.media-right {
    flex-shrink: 1;
    margin-left: 0;
    @include mobile {
        order: -1;
        width: 100%;
        margin-bottom: 1rem;
    }
}

.imageholder {
    img {
        width: 100%;
    }
}

.notification > .delete {
    right: .5rem;
    top: .5rem;
}


.hero-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-top: 52px;
}

.hero.is-large .hero-body {
    padding-bottom: 4rem;
    padding-top: 4rem;
    h1.title,
    h2.subtitle {
        text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.4);
    }
    h1.title  {
        font-size: $title-size;
        font-weight: bold;
    }
    h2.subtitle  {
        font-size: $subtitle-size;
    }
    @include tablet {
        padding-bottom: 12rem;
        padding-top: 12rem;
        h1.title  {
            font-size: $title-size * 1.25;
        }
        h2.subtitle  {
            font-size: $subtitle-size * 1.25;
        }
    }
    @include desktop {
        padding-bottom: 16rem;
        padding-top: 16rem;
        h1.title  {
            font-size: $title-size * 1.5;
        }
        h2.subtitle  {
            font-size: $subtitle-size * 1.5;
        }
    }

}

.footer {
  padding: 2rem 1.5rem
}

// https://antoningrele.github.io/img-brightness-normalization/normalizeBrightness.css
[data-background-image] { position: relative; }

[data-background-image] .dark-overlay {
	/*	This is the element that is going to darken the background image
	By default, it's entirely black, but the JS code will give it
	some transparency by setting its opacity to a calculated value.
	The brighter the image, the higher the opacity, to compensate ! */

	content: "";
	display: block;
	position: absolute;
	top: 0; left: 0;
	width: 100%; height: 100%;
	background-color: black;
}

pre {
    // Fix tags in PrismJS.
    .tag {
        margin: 0;
        padding: 0;
        background-color: transparent;
        display: inherit;
        font-size: inherit;
    }
    .number {
        font-size: 1em;
    }
}