@keyframes spinAround {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

h1, h2, h3, h4, h5, h6 {
  font-family: "Bitter", serif; }

p a,
li a {
  text-decoration: underline; }

/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity .5s ease; }
  #baguetteBox-overlay.visible {
    opacity: 1; }
  #baguetteBox-overlay .full-image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center; }
    #baguetteBox-overlay .full-image figure {
      display: inline;
      margin: 0;
      height: 100%; }
    #baguetteBox-overlay .full-image img {
      display: inline-block;
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
      vertical-align: middle;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); }
    #baguetteBox-overlay .full-image figcaption {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      line-height: 1.8;
      white-space: normal;
      color: #ccc;
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.6);
      font-family: sans-serif; }
    #baguetteBox-overlay .full-image:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left .4s ease, transform .4s ease; }
  #baguetteBox-slider.bounce-from-right {
    animation: bounceFromRight .4s ease-out; }
  #baguetteBox-slider.bounce-from-left {
    animation: bounceFromLeft .4s ease-out; }

@keyframes bounceFromRight {
  0% {
    margin-left: 0; }
  50% {
    margin-left: -30px; }
  100% {
    margin-left: 0; } }

@keyframes bounceFromLeft {
  0% {
    margin-left: 0; }
  50% {
    margin-left: 30px; }
  100% {
    margin-left: 0; } }

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px; }

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  transition: background-color .4s ease; }
  .baguetteBox-button:focus, .baguetteBox-button:hover {
    background-color: rgba(50, 50, 50, 0.9); }
  .baguetteBox-button#next-button {
    right: 2%; }
  .baguetteBox-button#previous-button {
    left: 2%; }
  .baguetteBox-button#close-button {
    top: 20px;
    right: 2%;
    right: calc(2% + 6px);
    width: 30px;
    height: 30px; }
  .baguetteBox-button svg {
    position: absolute;
    left: 0;
    top: 0; }

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px; }

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out; }

.baguetteBox-double-bounce2 {
  animation-delay: -1s; }

@keyframes bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
code[class*="language-"],
pre[class*="language-"] {
  color: black;
  background: none;
  text-shadow: 0 1px white;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none; }

pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
  text-shadow: none;
  background: #b3d4fc; }

pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
code[class*="language-"]::selection, code[class*="language-"] ::selection {
  text-shadow: none;
  background: #b3d4fc; }

@media print {
  code[class*="language-"],
  pre[class*="language-"] {
    text-shadow: none; } }

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto; }

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #f5f2f0; }

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
  white-space: normal; }

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray; }

.token.punctuation {
  color: #999; }

.token.namespace {
  opacity: .7; }

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905; }

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690; }

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  /* This background color was intended by the author of this theme. */
  background: rgba(255, 255, 255, 0.5); }

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a; }

.token.function,
.token.class-name {
  color: #DD4A68; }

.token.regex,
.token.important,
.token.variable {
  color: #e90; }

.token.important,
.token.bold {
  font-weight: bold; }

.token.italic {
  font-style: italic; }

.token.entity {
  cursor: help; }

.visually-hidden:not(:focus):not(:active) {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

.skip-link {
  font-family: "Bitter", serif;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  top: 60px;
  z-index: 1; }

.navbar-brand .navbar-item {
  font-family: "Bitter", serif;
  font-size: 1.5rem;
  font-weight: bold; }

.navbar-end {
  align-items: center; }
  @media screen and (max-width: 768px) {
    .navbar-end form {
      padding: 1rem; }
      .navbar-end form .control:first-child {
        width: 100%; } }

.navbar.is-primary, .boltform button.navbar {
  background-color: #D4D6C1; }

.navbar.is-primary .navbar-start > .navbar-item {
  color: #113e65c2;
  padding: 0.9rem 0.75rem 0.2rem; }

.navbar-item-logo {
  padding: 0.5rem 0.75rem; }
  .navbar-item-logo img {
    max-height: 50px; }

.notification ul {
  margin: 0.5em 1.4em;
  list-style-type: disc; }

.teaser {
  font-weight: bold;
  margin-bottom: 1rem; }

.card {
  display: flex;
  flex-direction: column;
  height: 100%; }

.card-content {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .card-content .button:last-child {
    margin-top: auto; }
  .card-content p {
    height: 100%; }

.section-latest-entries .buttons {
  margin-top: 1.5rem; }

.tags {
  margin-top: 3rem; }
  .tags .label {
    margin-right: .5em; }

.section-record .image {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.button {
  text-decoration: none; }

p.meta {
  margin-top: 1rem;
  color: #777;
  font-size: 90%; }

@media screen and (max-width: 768px) {
  .media {
    flex-direction: column; } }

.media-right {
  flex-shrink: 1;
  margin-left: 0; }
  @media screen and (max-width: 768px) {
    .media-right {
      order: -1;
      width: 100%;
      margin-bottom: 1rem; } }

.imageholder img {
  width: 100%; }

.notification > .delete {
  right: .5rem;
  top: .5rem; }

.hero-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 52px; }

.hero.is-large .hero-body {
  padding-bottom: 4rem;
  padding-top: 4rem; }
  .hero.is-large .hero-body h1.title,
  .hero.is-large .hero-body h2.subtitle {
    text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.4); }
  .hero.is-large .hero-body h1.title {
    font-size: 2rem;
    font-weight: bold; }
  .hero.is-large .hero-body h2.subtitle {
    font-size: 1.5rem; }
  @media screen and (min-width: 769px), print {
    .hero.is-large .hero-body {
      padding-bottom: 12rem;
      padding-top: 12rem; }
      .hero.is-large .hero-body h1.title {
        font-size: 2.5rem; }
      .hero.is-large .hero-body h2.subtitle {
        font-size: 1.875rem; } }
  @media screen and (min-width: 1024px) {
    .hero.is-large .hero-body {
      padding-bottom: 16rem;
      padding-top: 16rem; }
      .hero.is-large .hero-body h1.title {
        font-size: 3rem; }
      .hero.is-large .hero-body h2.subtitle {
        font-size: 2.25rem; } }

.footer {
  padding: 2rem 1.5rem; }

[data-background-image] {
  position: relative; }

[data-background-image] .dark-overlay {
  /*	This is the element that is going to darken the background image
	By default, it's entirely black, but the JS code will give it
	some transparency by setting its opacity to a calculated value.
	The brighter the image, the higher the opacity, to compensate ! */
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black; }

pre .tag {
  margin: 0;
  padding: 0;
  background-color: transparent;
  display: inherit;
  font-size: inherit; }

pre .number {
  font-size: 1em; }
